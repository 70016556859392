
import { Text, Flex, ScaleFade, Card, Box, Image, Icon, Button, Heading } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useInViewport } from "react-in-viewport";
import pure from "../../assets/images/pure.png";
import cashier from "../../assets/images/cashier.png";
import { FaChevronRight } from "react-icons/fa6";
import { FaChevronLeft } from "react-icons/fa";
import { Cards1, Cards2, Cards3, Cards4 } from "./Cards";

function Projects() {
  const ref = useRef(null);
  const { inViewport } = useInViewport(
    ref,
    { rootMargin: "-500px" },
    { disconnectOnLeave: false },
    {}
  );

  const [scrollPosition, setScrollPosition] = useState(0);

  const containerRef = useRef();
  const handleScroll = (scrollAmount) => {
    const newScrollPosition = scrollPosition + scrollAmount;
    setScrollPosition(newScrollPosition);
    containerRef.current.scrollLeft = newScrollPosition;
  };

  useEffect(() => {
    const container = containerRef.current;
    const handleScrollEnd = () => {
      if (container.scrollLeft === 0) {
        document.getElementById("left-button").hidden = true;
        document.getElementById("right-button").hidden = false;
      } else if (container.scrollLeft >= 4000) {
        document.getElementById("left-button").hidden = false;
        document.getElementById("right-button").hidden = true;
      } else {
        document.getElementById("left-button").hidden = false;
        document.getElementById("right-button").hidden = false;
      }
    };
    container.addEventListener("scroll", handleScrollEnd);
    return () => container.removeEventListener("scroll", handleScrollEnd);
  }, [scrollPosition]);

  return (
    <ScaleFade initialScale={0.9} in={inViewport}>
      <Flex
        alignItems={"center"}
        height={"100vh"}
        justify={"center"}
        gap={"15px"}
        padding={"200px"}
        flexDir={"column"}
        ref={ref}
      >
        <Heading fontSize={'78px'} fontWeight={'800'} mt={'40px'}>Projects</Heading>
        <Box margin={"0 auto"} position={"relative"} width={"95%"}>
          <Flex
            gap={"34px"}
            overflowX="auto"
            scrollBehavior={"smooth"}
            // marginTop={"14px"}
            justifyContent={{ base: "center", md: "flex-start" }}
            wrap={{ base: "wrap", md: "nowrap" }}
            borderRadius={"12px"}
            p={"10px"}
            ref={containerRef}
          >
            <Cards1/>
            <Cards2/>
            <Cards3/>
            <Cards4/>

          </Flex>
          <Box position={"absolute"} top={"50%"} ml={'20px'}>
            <Button 
              id="left-button"
              onClick={() => handleScroll(-1005)}
              hidden={false}
              height={"50px"}
              w={"50px"}
              borderRadius={'100%'}
              bg={'black'}
              color={'white'}
              _hover={{bg: 'white', color: 'black', border:'1px solid black'}}
              _active={{bg: 'white', color: 'black', opacity:'50%', border:'1px solid black'}}
            >
              <Icon as={FaChevronLeft} fontSize={"40px"} />
            </Button>
          </Box>

          <Box position={"absolute"} top={"50%"} right={"0"} mr={'20px'}>
            <Button
              id="right-button"
              height={"50px"}
              w={"50px"}
              borderRadius={'100%'}
              onClick={() => handleScroll(1005)}
              hidden={false}
              bg={'black'}
              color={'white'}
              _hover={{bg: 'white', color: 'black', border:'1px solid black'}}
              _active={{bg: 'white', color: 'black', opacity:'50%', border:'1px solid black'}}
            >
              <Icon as={FaChevronRight} fontSize={"40px"} />
            </Button>
          </Box>
        </Box>
      </Flex>
    </ScaleFade>
  );
}

export default Projects;
