import { Box, Card, Flex, Heading, ScaleFade, Text, Image } from "@chakra-ui/react";
import React, { useRef } from "react";
import { useInViewport } from "react-in-viewport";
import pure from "../../assets/images/pure.png";
import cashier from "../../assets/images/cashier.png";
import ticketing from "../../assets/images/ticketing.png";
import toefl from "../../assets/images/TOEFL.png";
import { useNavigate } from "react-router-dom";

function ProjectMobile() {
  const ref = useRef(null);
  const { inViewport } = useInViewport(
    ref,
    { rootMargin: "-200px" },
    { disconnectOnLeave: false },
    {}
  );
  const navigate = useNavigate()
  return (
    <ScaleFade initialScale={0.9} in={inViewport}>
      <Flex
        alignItems={"center"}
        flexDir={{ base: "column", lg: "row" }}
        height={"100vh"}
        justify={"center"}
        gap={{ base: "10px", md: "15px" }}
        padding={{ base: "20px", md: "50px", xl: "200px" }}
        ref={ref}
      >
        <Heading fontSize="30px" fontWeight={"800"} mt={"40px"}>
          Projects
        </Heading>
        <Box h={'500px'} overflow={'auto'} p={'10px'} borderRadius={'15px'}>
          <Card
            className="Pure"
            w={"100%"}
            cursor={"pointer"}
            overflow={"hidden"}
            borderRadius={"15px"}
            onClick={() => navigate(`/multi-warehouse`)}
            mb={"30px"}
          >
            <Box overflow={"hidden"} transition={"0.6s"} className="container-profile">
              <Image src={pure} objectFit={"cover"} className="card" />
            </Box>
            <Box p={"10px"}>
              <Text textAlign={"center"} fontSize={"14px"} fontWeight={"700"}>
                Multiwarehouse e-commerce
              </Text>
            </Box>
          </Card>
          <Card
            mb={"30px"}
            className="Pure"
            w={"100%"}
            cursor={"pointer"}
            overflow={"hidden"}
            borderRadius={"15px"}
            onClick={""}
          >
            <Box overflow={"hidden"} transition={"0.6s"} className="container-profile">
              <Image src={cashier} objectFit={"cover"} className="card" />
            </Box>
            <Box p={"10px"}>
              <Text textAlign={"center"} fontSize={"14px"} fontWeight={"700"}>
                Cashier Apps
              </Text>
            </Box>
          </Card>
          <Card
            mb={"40px"}
            className="Pure"
            w={"100%"}
            cursor={"pointer"}
            overflow={"hidden"}
            borderRadius={"15px"}
            onClick={""}
          >
            <Box overflow={"hidden"} transition={"0.6s"} className="container-profile">
              <Image src={ticketing} objectFit={"cover"} className="card" />
            </Box>
            <Box p={"10px"}>
              <Text textAlign={"center"} fontSize={"14px"} fontWeight={"700"}>
                Ticketing
              </Text>
            </Box>
          </Card>
          <Card
            mb={"40px"}
            className="Pure"
            w={"100%"}
            cursor={"pointer"}
            overflow={"hidden"}
            borderRadius={"15px"}
            onClick={""}
          >
            <Box overflow={"hidden"} transition={"0.6s"} className="container-profile">
              <Image src={toefl} objectFit={"cover"} className="card" />
            </Box>
            <Box p={"10px"}>
              <Text textAlign={"center"} fontSize={"14px"} fontWeight={"700"}>
                TOEFL Learning Management System
              </Text>
            </Box>
          </Card>
        </Box>
      </Flex>
    </ScaleFade>
  );
}

export default ProjectMobile;
