import { Box, Flex, Icon, Image, ScaleFade, Text } from "@chakra-ui/react";
import React, { useRef } from "react";
import surabaya from "../../assets/images/surabaya.png";
import { HiOutlineGlobeAsiaAustralia } from "react-icons/hi2";
import { LuClipboardCheck } from "react-icons/lu";
import { FaLinkedin } from "react-icons/fa";
import "./style.css";
import { useInViewport } from "react-in-viewport";

function Profile() {
  const ref = useRef(null);
  const { inViewport } = useInViewport(
    ref,
    { rootMargin: "-200px" },
    { disconnectOnLeave: false },
    {}
  );
  return (
    <ScaleFade initialScale={0.9} in={inViewport}>
      <Flex
        alignItems={"center"}
        flexDir={{ base: "column", lg: "row" }}
        height={"100vh"}
        justify={"center"}
        gap={{ base: "10px", md: "15px" }}
        padding={{ base: "20px", md: "50px", xl: "200px" }}
        ref={ref}
      >
        <Flex
          mt={{ base: "30px", md: "" }}
          gap={{ base: "10px", md: "20px" }}
          flexDir={"column"}
          width={{ base: "100%", lg: "50%" }}
        >
          <Flex
            className="container-profile"
            justifyContent={"center"}
            alignItems={"center"}
            h={{ base: "200px", md: "400px", lg: "450px" }}
            w={"100%"}
            borderRadius={"30px"}
            transition={"0.6s"}
            position={"relative"}
            overflow={"hidden"}
            bg={"#EBEBEB"}
          >
            <Box h={{ base: "200px", md: "450px" }} w={"100%"} className="card">
              <Image
                src={surabaya}
                objectFit={"cover"}
                objectPosition={"100% 80%"}
                boxSize={"100%"}
                borderRadius={"30px"}
                // transform={"rotateY(180deg)"}
                // opacity={"70%"}
              />
            </Box>
            <Flex
              h={{ base: "200px", md: "450px" }}
              w={"100%"}
              position="absolute"
              flexDir={"column"}
              textAlign={"left"}
              fontSize={{ base: "24px", md: "50px" }}
              lineHeight={{ base: "26px", md: "54px" }}
              p={{ base: "25px", md: "65px" }}
              zIndex={"2"}
              color={"blackAlpha.900"}
            >
              <Icon as={HiOutlineGlobeAsiaAustralia} boxSize={{ base: "50px", md: "100px" }} />
              <Text>Based in</Text>
              <Text fontWeight={"800"}>Surabaya,</Text>
              <Text fontWeight={"800"}>Indonesia.</Text>
              <Text fontWeight={"800"} color={"gray.500"}>
                GMT+7
              </Text>
            </Flex>
          </Flex>
          <a href="https://drive.google.com/uc?export=download&id=15m8li22fkwSrDuYlRIqHfTf6Nw8WGRYO">
            <Flex
              className="container-profile"
              justifyContent={"center"}
              alignItems={"center"}
              h={{ base: "50px", md: "100px" }}
              w={"100%"}
              borderRadius={{ base: "15px", md: "30px" }}
              transition={"0.6s"}
              position={"relative"}
              overflow={"hidden"}
              bg={"#EBEBEB"}
            >
              <Flex
                className="card"
                h={{ base: "50px", md: "100px" }}
                w={"100%"}
                position="absolute"
                justifyContent={"center"}
                alignItems={"center"}
                borderRadius={{ base: "15px", md: "30px" }}
                textAlign={"left"}
                fontSize={{ base: "24px", md: "34px" }}
                lineHeight={"64px"}
                px={{ base: "", md: "65px" }}
                gap={"20px"}
                color={"#0077B5"}
              >
                <Icon as={LuClipboardCheck} />
                <Text fontWeight={"700"}>Download CV</Text>
              </Flex>
            </Flex>
          </a>
        </Flex>

        <Flex
          gap={{ base: "10px", md: "20px" }}
          flexDir={"column"}
          width={{ base: "100%", lg: "50%" }}
        >
          <a
            href="https://www.linkedin.com/in/mahmud-rofii/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Flex
              className="container-profile"
              justifyContent={"center"}
              alignItems={"center"}
              h={{ base: "50px", md: "100px" }}
              w={"100%"}
              borderRadius={{ base: "15px", md: "30px" }}
              transition={"0.6s"}
              position={"relative"}
              overflow={"hidden"}
              bg={"#0077B5"}
            >
              <Flex
                className="card"
                h={{ base: "50px", md: "100px" }}
                w={"100%"}
                position="absolute"
                justifyContent={"center"}
                alignItems={"center"}
                borderRadius={{ base: "15px", md: "30px" }}
                textAlign={"left"}
                fontSize={{ base: "24px", md: "34px" }}
                lineHeight={"64px"}
                px={{ base: "", md: "65px" }}
                gap={"20px"}
                color={"white"}
              >
                <Icon as={FaLinkedin} />
                <Text fontWeight={"700"}>LinkedIn</Text>
              </Flex>
            </Flex>
          </a>
          <Flex
            className="container-profile"
            h={{ base: "200px", md: "350px", lg: "450px" }}
            w={"100%"}
            borderRadius={"30px"}
            justifyContent={"center"}
            alignItems={"center"}
            transition={"0.6s"}
            position={"relative"}
            overflow={"hidden"}
            bg={"#EBEBEB"}
          >
            <Box className="card" h={{ base: "200px", md: "450px" }} w={"100%"}>
              {/* <Image
              src={profile}
              objectFit={"cover"}
              boxSize={"100%"}
              borderRadius={"54px"}
              // opacity={"50%"}
            /> */}
            </Box>
            <Flex
              h={{ base: "200px", md: "450px" }}
              w={"100%"}
              position="absolute"
              justifyContent={"center"}
              alignItems={"center"}
              borderRadius={"56px"}
              textAlign={"left"}
              fontSize={{ base: "24px", md: "34px" }}
              lineHeight={{ base: "30px", md: "44px" }}
              p={{ base: "25px", md: "65px" }}
              gap={"20px"}
              color={"black"}
            >
              <Text>
                As a full-stack developer, I possess practical projects{" "}
                <Text as={"span"} fontWeight={"800"} color={"black"}>
                  aligned with industry needs.
                </Text>
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </ScaleFade>
  );
}

export default Profile;
