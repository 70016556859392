import { Box, Flex, Icon, Text, Link } from "@chakra-ui/react";
import { FaGithub, FaLinkedin, FaInstagram } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

export const Footer = () => {
    const email = "mahmudrofiie@gmail.com";
    const subject = "Subject of your email";
  
    const handleClick = () => {
      window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}`;
    };
  return (
    <Box as="nav" bg={"black"} p={4} left={0} right={0} zIndex={1000}>
      <Flex
        h={"50px"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={"40px"}
        position={"relative"}
        color={"white"}
        fontSize={"30px"}
        mb={"10px"}
      >
        <Link href="https://github.com/mhmdRfi/JCWD021001" isExternal>
          <Icon as={FaGithub} cursor={"pointer"} _hover={{ fontSize: "32px" }} />
        </Link>
        <Link href="https://www.linkedin.com/in/mahmud-rofii/" isExternal>
          <Icon as={FaLinkedin} cursor={"pointer"} _hover={{ fontSize: "32px" }} />
        </Link>
        <Link href={`mailto:${email}?subject=${encodeURIComponent(subject)}`} onClick={handleClick}>
          <Icon as={MdEmail} cursor={"pointer"} _hover={{ fontSize: "32px" }} />
        </Link>
        <Link href="https://www.instagram.com/mahmudrofiie/" isExternal>
          <Icon as={FaInstagram} cursor={"pointer"} _hover={{ fontSize: "32px" }} />
        </Link>
      </Flex>
      <Text mb={1} color={"white"} textAlign={"center"}>
        © Mahmud Rofi'i
      </Text>
    </Box>
  );
};
