// import "./App.css";
import { Box } from "@chakra-ui/react";
import { Route, Routes } from "react-router-dom";
import Home from "./Pages/home";
import MultiWarehouse from "./Pages/multiWarehouse";

function App() {
  return (
    <Box>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
      <Routes>
        <Route path="/multi-warehouse" element={<MultiWarehouse />} />
      </Routes>
    </Box>
  );
}

export default App;
