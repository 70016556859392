import { Box, Flex, Heading, Text, Image, SlideFade, ScaleFade } from "@chakra-ui/react";
import React, { useRef } from "react";
import photo from '../../assets/images/about.png'
import { useInViewport } from "react-in-viewport";

function Header() {

  const ref = useRef(null)
  const {inViewport} = useInViewport(
    ref,
    {rootMargin: '-300px'},
    {disconnectOnLeave: false},
    {}
  );

  return (
    <ScaleFade initialScale={0.9} in={inViewport}>
    <Flex alignItems={'center'} height={'100vh'} ref={ref}>
    <Flex p={{base: '40px', md:'100px', lg: '200px'}} gap={{base: '30px', md: '50px', lg:'65px'}} flexDir={{base: 'column', xl:'row'}}>
      <Box w={{base: '', xl:'70%'}} >
        <Text fontSize={{base: '16px', md:'25px', lg:'30px'}} fontWeight={'200'}>Hi, I am</Text>
        <Heading fontSize={{base: '35px', md:'55px', lg: '78px'}} fontWeight={'800'}>Mahmud Rofi'i</Heading>
        <Heading fontSize={{base: '20px', md:'30px', lg:'50px'}} fontWeight={'300'} mb={{base: '20px', md:'40px'}}>A Fullstack Developer</Heading>
        <Text fontSize={{base: '16px', md:'25px', lg: '30px'}} fontWeight={'200'} >
          My enthusiasm for technology and creating engaging digital experiences is boundless. I
          possess a foundational skill set that enables me to contribute to website development.
        </Text>
      </Box>
      <Flex w={{base: '', lg: '450px'}} h={{base: '', md: '400px', lg:'500px'}}>
        <Image src={photo} objectFit={'cover'} borderRadius={'54px'} />
      </Flex>
    </Flex>
    </Flex>
    </ScaleFade>
  );
}

export default Header;
