import {
  Flex,
  Heading,
  ScaleFade,
  Stack,
  FormLabel,
  InputGroup,
  Input,
  Button,
  Textarea,
} from "@chakra-ui/react";
import { useRef } from "react";
import { useInViewport } from "react-in-viewport";
import emailjs from "@emailjs/browser";
import toast from "react-hot-toast";

function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_88f7hfi", "template_kb3amto", form.current, {
        publicKey: "T3A68KGZndTOo8jvz",
      })
      .then(
        () => {
          toast.success("Message has been sent")
        },
        (error) => {
          toast.error("FAILED...", error.text);
        }
      );
    e.target.reset();
  };

  const ref = useRef(null);
  const { inViewport } = useInViewport(
    ref,
    { rootMargin: "-200px" },
    { disconnectOnLeave: false },
    {}
  );
  return (
    <ScaleFade initialScale={0.9} in={inViewport}>
      <Flex
        alignItems={"center"}
        height={"100vh"}
        justify={"center"}
        gap={"15px"}
        padding={{ base: "20px", md:'50px', xl: "200px" }}
        flexDir={"column"}
        ref={ref}
      >
        <Heading fontSize={{base: "30px", md:"78px"}} fontWeight={"800"}>
          Contact Me
        </Heading>
        <Flex
          justify={"center"}
          padding={{base: '0', md: "30px"}}
          margin={{base: '0', md:"20px auto"}}
          borderRadius={"10px"}
          alignItems={"center"}
          backgroundColor={"white"}
          width="400px"
        >
          <form ref={form} onSubmit={sendEmail}>
            <Stack width={{ base: "300px", md: "400px" }}>
              <FormLabel fontSize={"14px"} color={"gray"} margin={"0 0 0 0px"}>
                Name
              </FormLabel>
              <InputGroup>
                <Input
                  name="name"
                  placeholder="Your Name"
                  required
                  width={"100%"}
                  padding={"12px 20px"}
                  border={"1px solid #6666"}
                  fontSize={"16px"}
                />
              </InputGroup>

              <FormLabel fontSize={"14px"} color={"gray"} margin={"10px 0 0 0px"}>
                Email
              </FormLabel>
              <InputGroup>
                <Input
                type="email"
                  name="email"
                  required
                  placeholder="Your Email"
                  width={"100%"}
                  padding={"12px 20px"}
                  border={"1px solid #6666"}
                  fontSize={"16px"}
                />
              </InputGroup>
              <FormLabel fontSize={"14px"} color={"gray"} margin={"10px 0 0 0px"}>
                Subject
              </FormLabel>
              <InputGroup>
                <Input
                  name="subject"
                  required
                  placeholder="Subject"
                  width={"100%"}
                  padding={"12px 20px"}
                  border={"1px solid #6666"}
                  fontSize={"16px"}
                />
              </InputGroup>
              <FormLabel fontSize={"14px"} color={"gray"} margin={"10px 0 0 0px"}>
                Message
              </FormLabel>
              <InputGroup>
                <Textarea
                  name="message"
                  required
                  placeholder="Type your message"
                  width={"100%"}
                  padding={"12px 20px"}
                  border={"1px solid #6666"}
                  fontSize={"16px"}
                />
              </InputGroup>

              <Stack marginTop={"30px"}>
                <Button
                  type="submit"
                  bg={'black'}
                  color={'white'}
                  _hover={{bg: 'white', color: 'black', border:'1px solid black'}}
                  _active={{bg: 'white', color: 'black', opacity:'50%', border:'1px solid black'}}
                >
                  Send Me Message
                </Button>
              </Stack>
            </Stack>
          </form>
        </Flex>
      </Flex>
    </ScaleFade>
  );
}

export default Contact;
