import {
  Box,
  Button,
  Card,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  useDisclosure,
  Image,
  Text
} from "@chakra-ui/react";
import { useRef } from "react";
import pure from "../../assets/images/pure.png";
import cashier from "../../assets/images/cashier.png";
import ticketing from "../../assets/images/ticketing.png";
import toefl from "../../assets/images/TOEFL.png";

function DrawerProject() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();

  return (
    <>
      <Button
        ref={btnRef}
        bg={"transparent"}
        onClick={onOpen}
        color={"white"}
        fontWeight={"400"}
        _hover={{ bg: "transparent", fontWeight: "700" }}
      >
        Other Projects
      </Button>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} finalFocusRef={btnRef} size={{base: 'full', lg:'xs'}}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>My Projects</DrawerHeader>

          <DrawerBody>
            <Card
              className="Pure"
              w={"100%"}
              cursor={"pointer"}
              overflow={"hidden"}
              borderRadius={"15px"}
              onClick={""}
              mb={'30px'}
            >
              <Box
                overflow={"hidden"}
                transition={"0.6s"}
                className="container-profile"
              >
                <Image src={pure} objectFit={"cover"} className="card" />
              </Box>
              <Box p={"10px"}>
                <Text textAlign={"center"} fontSize={"14px"} fontWeight={"700"}>
                  Multiwarehouse e-commerce
                </Text>
              </Box>
            </Card>
            <Card
            mb={'30px'}
              className="Pure"
              w={"100%"}
              cursor={"pointer"}
              overflow={"hidden"}
              borderRadius={"15px"}
              onClick={""}
            >
              <Box
                overflow={"hidden"}
                transition={"0.6s"}
                className="container-profile"
              >
                <Image src={cashier} objectFit={"cover"} className="card" />
              </Box>
              <Box p={"10px"}>
                <Text textAlign={"center"} fontSize={"14px"} fontWeight={"700"}>
                  Cashier Apps
                </Text>
              </Box>
            </Card>
            <Card
            mb={'40px'}
              className="Pure"
              w={"100%"}
              cursor={"pointer"}
              overflow={"hidden"}
              borderRadius={"15px"}
              onClick={""}
            >
              <Box
                overflow={"hidden"}
                transition={"0.6s"}
                className="container-profile"
              >
                <Image src={ticketing} objectFit={"cover"} className="card" />
              </Box>
              <Box p={"10px"}>
                <Text textAlign={"center"} fontSize={"14px"} fontWeight={"700"}>
                  Ticketing
                </Text>
              </Box>
            </Card>
            <Card
            mb={'40px'}
              className="Pure"
              w={"100%"}
              cursor={"pointer"}
              overflow={"hidden"}
              borderRadius={"15px"}
              onClick={""}
            >
              <Box
                overflow={"hidden"}
                transition={"0.6s"}
                className="container-profile"
              >
                <Image src={toefl} objectFit={"cover"} className="card" />
              </Box>
              <Box p={"10px"}>
                <Text textAlign={"center"} fontSize={"14px"} fontWeight={"700"}>
                  TOEFL Learning Management System
                </Text>
              </Box>
            </Card>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default DrawerProject;
