import {
  Box,
  Flex,
  Heading,
  Image,
  Link,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import pure from "../../assets/images/pure.png";
import lp from "../../assets/images/LP1.png";
import signup from "../../assets/images/Sign-Up.png";
import admin from "../../assets/images/admin-dashboard.png";
import warehouse from "../../assets/images/create-warehouse.png";
import address from "../../assets/images/manage-address.png";
import profile from "../../assets/images/profile.png";
import shipping from "../../assets/images/shipping-cost.png";
import transaction from "../../assets/images/payment.png";
import product from "../../assets/images/product-detail.png";
import NavbarProjects from "../../components/navbarProject";
import { Footer } from "../../components/footer";

function MultiWarehouse() {
    
  return (
    <Box>
        <NavbarProjects/>
      <Box alignItems={"center"} justify={"center"} gap={"15px"}
      padding={{ base: "100px 20px", md:'100px 50px', xl: "100px 200px" }}>
        <Heading textAlign={"center"}>Pure Multiwarehouse E-Commerce</Heading>
        <Box m={"20px"} borderRadius={"20px"} overflow={"hidden"} mt={"20px"} boxShadow={"base"}>
          <Image src={pure} />
        </Box>
        <Box p={"20px"}>
          <Text>
            This is a website that served as a final project to fulfill the graduation requirements
            for the fullstack developer program at Purwadhika Digital Technology School{" "}
          </Text>
          <Text mt={"20px"}>This website uses following tech stacks:</Text>
          <Flex gap="30px" mt={"20px"}>
            <UnorderedList>
              <ListItem>Javascript</ListItem>
              <ListItem>Node JS</ListItem>
              <ListItem>React JS</ListItem>
              <ListItem>Chakra UI</ListItem>
            </UnorderedList>
            <UnorderedList>
              <ListItem>Express JS</ListItem>
              <ListItem>Sequelize</ListItem>
              <ListItem>MySQL</ListItem>
            </UnorderedList>
          </Flex>
          <Heading as={"h2"} mt={"20px"}>
            What is Pure?
          </Heading>
          <Text mt={"20px"}>
            Pure is an e-commerce clothing application that uses more than one warehouse to store
            goods to increase delivery speed and reduce delivery costs. When there is a new order,
            the system will automatically forward the order to the nearest warehouse location from
            the user's address. From the user's perspective, it will not be seen that this
            application has more than one storage warehouse. The stock visible to the user is the
            total stock from all warehouses.
          </Text>
          <Heading as={"h2"} mt={"20px"} mb={"20px"}>
            Features
          </Heading>
          <Text mt={"20px"} as={"b"} fontSize={"20px"}>
            Homepage
          </Text>
          <Flex mt={"20px"} gap={"40px"} mb={"20px"} flexDir={{base: 'column', md: 'row'}}>
            <Box
              h={{base: '200px', md:"300px"}}
              overflow={"auto"}
              overflowX={"auto"}
              w={{base: '100%', md:"70%"}}
              boxShadow={"base"}
              borderRadius={"10px"}
            >
              <Image src={lp} />
            </Box>
            <Box w={{base: '100%', md:"30%"}}>
              <Text>This feature comprises:</Text>
              <UnorderedList mt={"20px"}>
                <ListItem>Navigation bar</ListItem>
                <ListItem>Hero section</ListItem>
                <ListItem>Product categories</ListItem>
                <ListItem>Footer</ListItem>
              </UnorderedList>
            </Box>
          </Flex>
          <Text mt={"20px"} as={"b"} fontSize={"20px"}>
            User Management
          </Text>
          <Flex mt={"20px"} gap={"40px"} mb={"20px"} flexDir={{base: 'column', md: 'row'}}>
            <Box
              h={{base: '200px', md:"300px"}}
              overflow={"auto"}
              overflowX={"auto"}
              w={{base: '100%', md:"70%"}}
              boxShadow={"base"}
              borderRadius={"10px"}
            >
              <Text textAlign={"center"}>User Auth</Text>
              <Image src={signup} mb={"20px"} />
              <Text textAlign={"center"}>Profile</Text>
              <Image src={profile} mb={"20px"} />
              <Text textAlign={"center"}>Manage Address</Text>
              <Image src={address} mb={"20px"} />
            </Box>
            <Box w={{base: '100%', md:"30%"}}>
              <Text>This feature comprises:</Text>
              <UnorderedList mt={"20px"}>
                <ListItem>User Authorization</ListItem>
                <ListItem>User Registration</ListItem>
                <ListItem>Email Verification and Set Password</ListItem>
                <ListItem>User Login</ListItem>
                <ListItem>Reset Password</ListItem>
                <ListItem>User Profile</ListItem>
                <ListItem>Manage User Address</ListItem>
              </UnorderedList>
            </Box>
          </Flex>
          <Text mt={"20px"} as={"b"} fontSize={"20px"}>
            Admin Management
          </Text>
          <Flex mt={"20px"} gap={"40px"} mb={"20px"} flexDir={{base: 'column', md: 'row'}}>
            <Box
              h={{base: '200px', md:"300px"}}
              overflow={"auto"}
              overflowX={"auto"}
              w={{base: '100%', md:"70%"}}
              boxShadow={"base"}
              borderRadius={"10px"}
            >
              <Text textAlign={"center"}>User Management</Text>
              <Image src={admin} mb={"20px"} />
              <Text textAlign={"center"}>Create Warehouse</Text>
              <Image src={warehouse} mb={"20px"} />
            </Box>
            <Box w={{base: '100%', md:"30%"}}>
              <Text>This feature comprises:</Text>
              <UnorderedList mt={"20px"}>
                <ListItem>Admin Authorization</ListItem>
                <ListItem>Manage User Data</ListItem>
                <ListItem>Warehouse Management</ListItem>
                <ListItem>Assign Warehouse Admin</ListItem>
              </UnorderedList>
            </Box>
          </Flex>
          <Text mt={"20px"} as={"b"} fontSize={"20px"}>
            Product Management
          </Text>
          <Flex mt={"20px"} gap={"40px"} mb={"20px"} flexDir={{base: 'column', md: 'row'}}>
            <Box
              h={{base: '200px', md:"300px"}}
              overflow={"auto"}
              overflowX={"auto"}
              w={{base: '100%', md:"70%"}}
              boxShadow={"base"}
              borderRadius={"10px"}
            >
              <Text textAlign={"center"}>Product Detail</Text>
              <Image src={product} mb={"20px"} />
            </Box>
            <Box w={{base: '100%', md:"30%"}}>
              <Text>This feature comprises:</Text>
              <UnorderedList mt={"20px"}>
                <ListItem>Product Catalog and Product Search</ListItem>
                <ListItem>Product Detail</ListItem>
                <ListItem>Product Admin Management</ListItem>
                <ListItem>Inventory Management</ListItem>
                <ListItem>Report</ListItem>
              </UnorderedList>
            </Box>
          </Flex>
          <Text mt={"20px"} as={"b"} fontSize={"20px"}>
            Transaction
          </Text>
          <Flex mt={"20px"} gap={"40px"} mb={"20px"} flexDir={{base: 'column', md: 'row'}}>
            <Box
              h={{base: '200px', md:"300px"}}
              overflow={"auto"}
              overflowX={"auto"}
              w={{base: '100%', md:"70%"}}
              boxShadow={"base"}
              borderRadius={"10px"}
            >
              <Text textAlign={"center"}>Transaction</Text>
              <Image src={transaction} mb={"20px"} />
              <Text textAlign={"center"}>Shipping Cost</Text>
              <Image src={shipping} mb={"20px"} />
            </Box>
            <Box w={{base: '100%', md:"30%"}}>
              <Text>This feature comprises:</Text>
              <UnorderedList mt={"20px"}>
                <ListItem>Shopping Cart</ListItem>
                <ListItem>Checkout Process & Order Tracking</ListItem>
                <ListItem>Location determination and Shipping cost</ListItem>
                <ListItem>Order Management</ListItem>
                <ListItem>Inventory Management</ListItem>
              </UnorderedList>
            </Box>
          </Flex>
          <Box mt={"50px"}>
            <Text>You can see more features that we build in this Github repository</Text>
            <Text as={"b"}>
              Github Repo{" "}
              <Link color="teal.500" href="https://github.com/mhmdRfi/JCWD021001">
                here
              </Link>
            </Text>
          </Box>
        </Box>
      </Box>
      <Footer/>
    </Box>
  );
}

export default MultiWarehouse;
