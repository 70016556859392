import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { AiFillHome } from "react-icons/ai";
import { FaSmile } from "react-icons/fa";
import { RiComputerFill, RiMessage2Fill } from "react-icons/ri";
import { Link } from "react-router-dom";

export const Navbar = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [profileIsHovered, setProfileIsHovered] = useState(false);
  const [projectIsHovered, setProjectIsHovered] = useState(false);
  const [contactIsHovered, setContactIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleProfileMouseEnter = () => {
    setProfileIsHovered(true);
  };
  const handleProfileMouseLeave = () => {
    setProfileIsHovered(false);
  };
  const handleProjectsMouseEnter = () => {
    setProjectIsHovered(true);
  };
  const handleProjectsMouseLeave = () => {
    setProjectIsHovered(false);
  };
  const handleContactMouseEnter = () => {
    setContactIsHovered(true);
  };
  const handleContactMouseLeave = () => {
    setContactIsHovered(false);
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlNavbar = () => {
    if (window.scrollY > lastScrollY) {
      setShow(false); 
    } else {
      setShow(true);  
    }

    setLastScrollY(window.scrollY); 
  };

  useEffect(() => {
    window.addEventListener('scroll', controlNavbar);

    // cleanup function
    return () => {
       window.removeEventListener('scroll', controlNavbar);
    };
  }, [lastScrollY]);

  return (
    <Box
      as="nav"
      bg={"white"}
      p={2}
      position="fixed"
      top={show ? 0 : -60}
      left={0}
      right={0}
      zIndex={1000}
      transition="top 0.3s ease-in-out"
      w={"100vw"}
    >
      <Flex
        h={"50px"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={{ base: "10px", md: "60px" }}
        position={"relative"}
      >
        <Box
          onClick={() => scrollToSection("header")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Flex
            flexDir={"column"}
            justifyContent={"center"}
            alignItems="center"
            w={"60px"}
            position={"relative"}
          >
            <Icon as={AiFillHome} boxSize={isHovered ? "30px" : "28px"} cursor={"pointer"} />
            <Box
              bg={"black"}
              p={0.5}
              textAlign={"center"}
              borderRadius={"10px"}
              fontSize={"12px"}
              w={"100%"}
              position={"absolute"}
              top={"35px"}
              display={isHovered ? "block" : "none"}
            >
              <Text color={"white"}>Home</Text>
            </Box>
          </Flex>
        </Box>
        <Box
          onClick={() => scrollToSection("profile")}
          onMouseEnter={handleProfileMouseEnter}
          onMouseLeave={handleProfileMouseLeave}
        >
          <Flex
            flexDir={"column"}
            justifyContent={"center"}
            alignItems="center"
            w={"60px"}
            position={"relative"}
          >
            <Icon as={FaSmile} cursor={"pointer"} boxSize={profileIsHovered ? "30px" : "28px"} />
            <Box
              bg={"black"}
              p={0.5}
              textAlign={"center"}
              borderRadius={"10px"}
              fontSize={"12px"}
              w={"100%"}
              position={"absolute"}
              top={"35px"}
              display={profileIsHovered ? "block" : "none"}
            >
              <Text color={"white"}>Profile</Text>
            </Box>
          </Flex>
        </Box>
        <Box
          onClick={() => scrollToSection("projects")}
          onMouseEnter={handleProjectsMouseEnter}
          onMouseLeave={handleProjectsMouseLeave}
        >
          <Flex
            flexDir={"column"}
            justifyContent={"center"}
            alignItems="center"
            w={"60px"}
            position={"relative"}
          >
            <Icon
              as={RiComputerFill}
              boxSize={projectIsHovered ? "30px" : "28px"}
              cursor={"pointer"}
            />
            <Box
              bg={"black"}
              p={0.5}
              textAlign={"center"}
              borderRadius={"10px"}
              fontSize={"12px"}
              w={"100%"}
              position={"absolute"}
              top={"35px"}
              display={projectIsHovered ? "block" : "none"}
            >
              <Text color={"white"}>Projects</Text>
            </Box>
          </Flex>
        </Box>
        <Box
          onClick={() => scrollToSection("contacts")}
          onMouseEnter={handleContactMouseEnter}
          onMouseLeave={handleContactMouseLeave}
        >
          <Flex
            flexDir={"column"}
            justifyContent={"center"}
            alignItems="center"
            w={"60px"}
            position={"relative"}
          >
            <Icon
              as={RiMessage2Fill}
              boxSize={contactIsHovered ? "30px" : "28px"}
              cursor={"pointer"}
            />
            <Box
              bg={"black"}
              p={0.5}
              textAlign={"center"}
              borderRadius={"10px"}
              fontSize={"12px"}
              w={"100%"}
              position={"absolute"}
              top={"35px"}
              display={contactIsHovered ? "block" : "none"}
            >
              <Text color={"white"}>Contacts</Text>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};
