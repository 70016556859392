import {
  Box,
  Flex,
  Link,
} from "@chakra-ui/react";
import DrawerProject from "./drawer";
import { useEffect, useState } from "react";

function NavbarProjects() {
    const [show, setShow] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
  
    const controlNavbar = () => {
      if (window.scrollY > lastScrollY) {
        setShow(false); 
      } else {
        setShow(true);  
      }
  
      setLastScrollY(window.scrollY); 
    };
  
    useEffect(() => {
      window.addEventListener('scroll', controlNavbar);
  
      // cleanup function
      return () => {
         window.removeEventListener('scroll', controlNavbar);
      };
    }, [lastScrollY]);
  return (
    <Box
      as="nav"
      bg={"black"}
      p={2}
      position="fixed"
      top={show ? 0 : -60}
      left={0}
      right={0}
      zIndex={1000}
      transition="top 0.3s ease-in-out"
    >
      <Flex
        h={"50px"}
        justifyContent={"flex-start"}
        pl={{base:'20px', md: "200px"}}
        alignItems={"center"}
        gap={{base: '20px', md:"60px"}}
        position={"relative"}
        color={"white"}
      >
        <Link href="/" _hover={{ textDecoration: "none", fontWeight: "700" }}>
          Home
        </Link>
       <DrawerProject/>
      </Flex>
    </Box>
  );
}

export default NavbarProjects;
