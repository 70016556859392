import { Box, Card, Flex, Image, Text } from "@chakra-ui/react";
import pure from "../../assets/images/pure.png";
import cashier from "../../assets/images/cashier.png";
import ticketing from "../../assets/images/ticketing.png";
import toefl from "../../assets/images/TOEFL.png";
import "./style.css";
import { useNavigate } from "react-router-dom";

export const Cards1 = () => {
  const navigate = useNavigate();
  return (
    <>
      <Card
        className="Pure"
        w={"100%"}
        flex="0 0 auto"
        cursor={"pointer"}
        overflow={"hidden"}
        borderRadius={"15px"}
        h={"550px"}
        onClick={() => navigate(`/multi-warehouse`)}
      >
        <Box h={"450px"} overflow={"hidden"} transition={"0.6s"} className="container-profile">
          <Image src={pure} objectFit={"cover"} className="card" />
        </Box>
        <Box>
          <Flex p={"10px 10px 0 10px"} gap={"5px"}>
            <Box borderRadius={"5px"} bg={"black"} color={"white"} px={"5px"} fontSize={"12px"}>
              <Text>Javascript</Text>
            </Box>
            <Box borderRadius={"5px"} bg={"black"} color={"white"} px={"5px"} fontSize={"12px"}>
              <Text>Node</Text>
            </Box>
            <Box borderRadius={"5px"} bg={"black"} color={"white"} px={"5px"} fontSize={"12px"}>
              <Text>React JS</Text>
            </Box>
            <Box borderRadius={"5px"} bg={"black"} color={"white"} px={"5px"} fontSize={"12px"}>
              <Text>Chakra UI</Text>
            </Box>
            <Box borderRadius={"5px"} bg={"black"} color={"white"} px={"5px"} fontSize={"12px"}>
              <Text>Express JS</Text>
            </Box>
            <Box borderRadius={"5px"} bg={"black"} color={"white"} px={"5px"} fontSize={"12px"}>
              <Text>Sequelize</Text>
            </Box>
            <Box borderRadius={"5px"} bg={"black"} color={"white"} px={"5px"} fontSize={"12px"}>
              <Text>MySQL</Text>
            </Box>
          </Flex>
          <Box p={"10px"}>
            <Text textAlign={"center"} fontSize={"24px"} fontWeight={"700"}>
              Multi Warehouse e-Commerce
            </Text>
            <Text textAlign={"left"} fontSize={"16px"} p={"10px"}>
              An e-commerce application that uses more than one warehouse to store goods to increase
              delivery speed and reduce delivery costs.
            </Text>
          </Box>
        </Box>
      </Card>
    </>
  );
};

export const Cards2 = () => {
  return (
    <Card
      className="Pure"
      w={"100%"}
      flex="0 0 auto"
      cursor={"pointer"}
      overflow={"hidden"}
      borderRadius={"15px"}
      h={"550px"}
    >
      <Box h={"450px"} overflow={"hidden"} transition={"0.6s"} className="container-profile">
        <Image src={cashier} objectFit={"cover"} className="card" />
      </Box>
      <Flex p={"10px 10px 0 10px"} gap={"5px"}>
        <Box borderRadius={"5px"} bg={"black"} color={"white"} px={"5px"} fontSize={"12px"}>
          <Text>Typescript</Text>
        </Box>
        <Box borderRadius={"5px"} bg={"black"} color={"white"} px={"5px"} fontSize={"12px"}>
          <Text>Node</Text>
        </Box>
        <Box borderRadius={"5px"} bg={"black"} color={"white"} px={"5px"} fontSize={"12px"}>
          <Text>React JS</Text>
        </Box>
        <Box borderRadius={"5px"} bg={"black"} color={"white"} px={"5px"} fontSize={"12px"}>
          <Text>Chakra UI</Text>
        </Box>
        <Box borderRadius={"5px"} bg={"black"} color={"white"} px={"5px"} fontSize={"12px"}>
          <Text>Express JS</Text>
        </Box>
        <Box borderRadius={"5px"} bg={"black"} color={"white"} px={"5px"} fontSize={"12px"}>
          <Text>Sequelize</Text>
        </Box>
        <Box borderRadius={"5px"} bg={"black"} color={"white"} px={"5px"} fontSize={"12px"}>
          <Text>MySQL</Text>
        </Box>
      </Flex>
      <Box p={"10px"}>
        <Text textAlign={"center"} fontSize={"24px"} fontWeight={"700"}>
          Cashier Apps
        </Text>
        <Text textAlign={"left"} fontSize={"16px"} p={"10px"}>
          An application that records sales of a cafe and is managed by admin and cashier
        </Text>
      </Box>
    </Card>
  );
};

export const Cards3 = () => {
  return (
    <Card
      className="Pure"
      w={"100%"}
      flex="0 0 auto"
      cursor={"pointer"}
      overflow={"hidden"}
      borderRadius={"15px"}
      h={"550px"}
    >
      <Box h={"450px"} overflow={"hidden"} transition={"0.6s"} className="container-profile">
        <Image src={ticketing} objectFit={"cover"} className="card" />
      </Box>
      <Flex p={"10px 10px 0 10px"} gap={"5px"}>
        <Box borderRadius={"5px"} bg={"black"} color={"white"} px={"5px"} fontSize={"12px"}>
          <Text>Javascript</Text>
        </Box>
        <Box borderRadius={"5px"} bg={"black"} color={"white"} px={"5px"} fontSize={"12px"}>
          <Text>Node</Text>
        </Box>
        <Box borderRadius={"5px"} bg={"black"} color={"white"} px={"5px"} fontSize={"12px"}>
          <Text>React JS</Text>
        </Box>
        <Box borderRadius={"5px"} bg={"black"} color={"white"} px={"5px"} fontSize={"12px"}>
          <Text>Chakra UI</Text>
        </Box>
        <Box borderRadius={"5px"} bg={"black"} color={"white"} px={"5px"} fontSize={"12px"}>
          <Text>Express JS</Text>
        </Box>
        <Box borderRadius={"5px"} bg={"black"} color={"white"} px={"5px"} fontSize={"12px"}>
          <Text>Sequelize</Text>
        </Box>
        <Box borderRadius={"5px"} bg={"black"} color={"white"} px={"5px"} fontSize={"12px"}>
          <Text>MySQL</Text>
        </Box>
      </Flex>
      <Box p={"10px"}>
        <Text textAlign={"center"} fontSize={"24px"} fontWeight={"700"}>
          Ticketing
        </Text>
        <Text textAlign={"left"} fontSize={"16px"} p={"10px"}>
          A website to buy and sell ticket events. Organizer can post and sell their event while
          attendee can search events and buy tickets.
        </Text>
      </Box>
    </Card>
  );
};

export const Cards4 = () => {
  return (
      <Card
        className="Pure"
        w={"100%"}
        flex="0 0 auto"
        cursor={"pointer"}
        overflow={"hidden"}
        borderRadius={"15px"}
        h={"550px"}
      >
        <Box h={"450px"} overflow={"hidden"} transition={"0.6s"} className="container-profile">
          <Image src={toefl} objectFit={"cover"} className="card" />
        </Box>
        <Flex p={"10px 10px 0 10px"} gap={"5px"}>
          <Box borderRadius={"5px"} bg={"black"} color={"white"} px={"5px"} fontSize={"12px"}>
            <Text>WordPress</Text>
          </Box>
          <Box borderRadius={"5px"} bg={"black"} color={"white"} px={"5px"} fontSize={"12px"}>
            <Text>LearnDash</Text>
          </Box>
          <Box borderRadius={"5px"} bg={"black"} color={"white"} px={"5px"} fontSize={"12px"}>
            <Text>Elementor</Text>
          </Box>
          <Box borderRadius={"5px"} bg={"black"} color={"white"} px={"5px"} fontSize={"12px"}>
            <Text>Happy Elementor</Text>
          </Box>
        </Flex>
        <Box p={"10px"}>
          <Text textAlign={"center"} fontSize={"24px"} fontWeight={"700"}>
            TOEFL Learning Management System
          </Text>
          <Text textAlign={"left"} fontSize={"16px"} p={"10px"}>
            A website of an online course company. It is used as a company website as well as a
            learning management system for its student. The LMS comprises video learning, materials,
            quizzez, and certificate upon completion.
          </Text>
        </Box>
      </Card>
  );
};
