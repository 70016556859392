// import "./App.css";
import { Box } from "@chakra-ui/react";
import Header from "../../components/headers";
import Profile from "../../components/profile";
import Projects from "../../components/projects";
import Contact from "../../components/contact";
import { Navbar } from "../../components/navbar";
import './index.css'
import ProjectMobile from "../../components/projects/ProjectMobile";

function Home() {
  
  return (
    <Box w={'100vw'}>
      <Navbar />
      <Box id="header">
        <Header />
      </Box>
      <Box id="profile">
        <Profile />
      </Box>
      <Box id="projects" display={{base: 'none', md: 'block'}}>
        <Projects />
      </Box>
      <Box  id="projects" display={{base: 'block', md: 'none'}}>
        <ProjectMobile/>
      </Box>
      <Box  id="contacts">
        <Contact />
      </Box>
    </Box>
  );
}

export default Home;
